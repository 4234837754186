import styled, { keyframes } from 'styled-components'

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

export const ItemMethod = styled.div`
  display: flex;
  padding: 16px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-radius: 12px;
  background: #f5f5f5;

  -webkit-transition: border 700ms ease-in-out;
  -moz-transition: border 700ms ease-in-out;
  -o-transition: border 700ms ease-in-out;
  transition: border 700ms ease-in-out;

  -webkit-transition: background-color 800ms ease-in-out;
  -moz-transition: background-color 800ms ease-in-out;
  -o-transition: background-color 800ms ease-in-out;
  transition: background-color 800ms ease-in-out;

  animation: ${fadeIn} 1.5s ease-in-out;

  &.active {
    border: 1px solid ${(props) => props.color};
    background-color: #fafafa;
  }

  .content {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 7px;
  }

  .decs-label-by {
    color: #595959;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
  }
`

export const ItemMethodTitle = styled.div`
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
  display: flex;
  flex-direction: column;
  gap: 6px;

  @media (max-width: 768px) {
    font-size: 14px;
  }
`

export const ItemMethodSmallLogo = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
`

export const ItemMethodLogo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 40px;
  height: 40px;
  padding: 0.25rem;
  text-align: center;
  img {
    max-width: 100%;
    height: 100%;
    object-fit: contain;
    vertical-align: middle;
  }
`

export const ItemMethodTitleLogo = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #262626;
  margin-left: 5px;
`

export const ButtonPayment = styled.button`
  width: 100%;
  pointer-events: ${(props) => (props.disabled ? 'none' : null)};
  border-radius: 0.25rem;
  padding: 1rem;
  margin-top: 1.25rem;
  color: white;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.5rem;
  background-color: ${(props) => props.color || '#ed1c24'};
  text-align: center;
  cursor: pointer;
  border: none;
  :disabled {
    opacity: 0.75;
    animation: ${fadeIn} 1.5s;
  }
`

export const ItemPowered = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  row-gap: 10px;
  margin-top: 5px;
  .decs-label-by {
    color: #595959;
    font-weight: 500;
    font-size: 11px;
    line-height: 20px;
  }
  img {
    width: 22px;
    margin-left: 10px;
  }
`

export const ItemOrder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

export const ValueOrder = styled.div`
  font-weight: 500;
  color: #262626;
  letter-spacing: 1px;
  font-size: 0.875rem;
`

export const AmountOrder = styled.div`
  font-weight: 700;
  color: #002970;
  letter-spacing: 0.75px;
  font-size: 0.875rem;
`

export const HrOrder = styled.div`
  font-size: 0.875rem;
  border-width: 1px;
  border-style: dashed;
  border-color: #bfbfbf;
  height: 0;
  margin-top: 20px;
  margin-bottom: 4px;
`

export const RadioInput = styled.input`
  width: 20px;
  height: 20px;

  &:checked {
    accent-color: ${(props) => props.color};
  }
`

// order info

export const OrderInfoContainer = styled.div`
  border-radius: 16px;
  width: 440px;
  font-family: 'Montserrat';

  //responsive
  @media (max-width: 768px) {
    width: 100%;
    padding: 16px;
    gap: 24px;
  }
`

export const TimeTag = styled.div`
  padding: 16px;
  border-radius: 16px 16px 0 0;
  display: flex;
  gap: 4px;
  align-items: center;
  justify-content: center;
  width: 440px;

  /* background: #2EB5531A; */
  /* color: #1D963E; */
  background: ${(props) => (props?.isTimeout ? '#ED1C241A' : '#2EB5531A')};
  color: ${(props) => (props?.isTimeout ? '#F5222D' : '#1D963E')};
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  transition: all 0.2s linear;
`

export const OrderBody = styled.div`
  background: white;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 40px;
  border-radius: 0 0 16px 16px;
`

export const LabelOrder = styled.div`
  font-weight: 600;
  color: #000;
  font-size: 20px;
  line-height: 28px;
`

export const LabelInfo = styled.div`
  font-weight: 400;
  color: #262626;
  font-size: 14px;
  line-height: 20px;
`

export const Amount = styled.div`
  font-weight: 700;
  color: #262626;
  font-size: 24px;
  line-height: 32px;
`

export const GroupInfoLine = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 24px;
  width: 100%;
`

export const Line = styled.div`
  width: 100%;
  height: 1px;
  background-color: #e8e8e8;
  margin: 16px 0px 16px 0px;
`

export const Buttons = styled.div`
  display: flex;
  justify-content: between;
  gap: 16px;
  width: 100%;
  font-family: 'Montserrat';
`

export const SolidButton = styled.button`
  width: 100%;
  border-radius: ${(props) => (props?.rounded ? '4px' : 'none')};
  padding: 12px 32px 12px 32px;
  ${'' /* background-color: ${(props) => props?.color || "#ED1C24"}; */}
  background: linear-gradient(302.95deg, #00C1F3 -14.84%, #1E2A7B 100.48%);
  display: flex;
  justify-content: center;
  color: #fefefe;
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  border: none;
  cursor: pointer;
  font-family: 'Montserrat';

  &:disabled {
    opacity: 0.75;
    animation: ${fadeIn} 1.5s;
  }
`

export const BorderGradientBtn = styled.div`
  padding: 1px;
  background-image: linear-gradient(
    302.95deg,
    #00c1f3 -14.84%,
    #1e2a7b 100.48%
  );
  ${'' /* border-radius: ${(props) => (props.rounded ? '4px' : '0px')}; */}
  border-radius: 6px;
  width: 100%;

  &:hover {
    opacity: 0.75;
  }
`

export const BorderButton = styled.button`
  width: 100%;
  padding: 12px 32px 12px 32px;
  display: flex;
  justify-content: center;
  ${'' /* border: 1px solid ${(props) => props.color || '#ED1C24'}; */}
  border: 1px solid #1F2B7B;
  ${'' /* border-radius: ${(props) => (props.rounded ? '4px' : '0px')}; */}
  border-radius: 6px;
  color: ${(props) => props.color || '#ED1C24'};
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  cursor: pointer;
  background: none;
  font-family: 'Montserrat';
  background-color: #fff;

  &:disabled {
    opacity: 0.75;
    animation: ${fadeIn} 1.5s;
  }

  &:hover {
    opacity: 0.75;
  }
`
