import axiosClient from './axiosClient'

const CHECKOUT_SERVICE = `/checkout-service`
const SUBMIT_TRANSACTION_ORDER = `${CHECKOUT_SERVICE}/txn-order`
const STATUS = `${CHECKOUT_SERVICE}/txn`
const SETTINGS_WIDGET = `${CHECKOUT_SERVICE}/setting`
const GET_MERCHANT_INFO = `${CHECKOUT_SERVICE}/merchant`
const CHECKOUT_PAYMENT = '/checkout/payments'

const checkoutService = {
  submitTxnOrder: (params) => {
    return axiosClient.post(
      `${CHECKOUT_SERVICE}/txn-order?locale=${params.locale}`,
      params.data
    )
  },
  generateQrCode: (params) => {
    return axiosClient.post(CHECKOUT_PAYMENT, params)
  },
  getStatus: (txn_id, txn_type) => {
    return axiosClient.get(`${STATUS}?txn_id=${txn_id}&txn_type=${txn_type}`)
  },
  getSettingsWidget: (app_id) => {
    return axiosClient.get(`${SETTINGS_WIDGET}/${app_id}`)
  },
  getMerchantInfo: (merchant_id) => {
    return axiosClient.get(`${GET_MERCHANT_INFO}/${merchant_id}`)
  },
  cancelPayment: (params) => {
    return axiosClient.put(CHECKOUT_PAYMENT, params)
  },
}

export default checkoutService
