import styled, { keyframes } from 'styled-components'

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

export const WrapperASW = styled.div`
  width: 100%;
  height: 100%;
  font-family: 'Montserrat';
  font-size: 0.75rem;
`

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const ContainerPage = styled.div`
  width: 100%;
  height: 100vh;
  background-color: rgb(229 229 229 / 50%);
  display: flex;
  align-items: center;
  justify-content: center;
`

export const BoxPayment = styled.div`
  display: flex;
  gap: 32px;
  width: 100%;

  //responsive
  @media (max-width: 1024px) {
    flex-direction: column;
    align-items: center;
    padding: 0 16px;
  }
`

export const Title = styled.div`
  padding: 0rem;
  color: #002970;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  animation: ${fadeIn} 2s;
`
export const BoxMethod = styled.div`
  padding: 0rem;
  margin-top: 24px;
`
