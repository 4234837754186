import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { Container, Title, Input, ButtonSubmit } from "./styled";
import { BoxPayment } from "../checkout/styled";

import LoadingPage from "../../components/loading";
import OrderInfo from "../../components/checkout/OrderInfo";
import axiosClient from "../../services/axiosClient";
import { generateUniqueId } from "../../utils/vpb";

const PaymentCheckoutCash = ({ props }) => {
    const location = useLocation();
    const query = new URLSearchParams(location.search);

    const [uniqueId, setUniqueId] = useState(generateUniqueId());
    const [inputValue, setInputValue] = useState("");

    const onSubmit = (event) => {
        event.preventDefault();
        submitTxn();
        window.location.href = `/payment-callback?order-id=${query.get("order_id")}&status=true`;
    };

    const submitTxn = async () => {
        let data = {
            customer_id: props.order?.merchant_id,
            bill_id: props.order?.order_id,
            amount: props.order?.amount,
            txn_id: uniqueId,
        };
        await axiosClient({
            method: "POST",
            url: `${process.env.REACT_APP_API_ENDPOINT}/checkout-service/txn-cash`,
            data,
        });
    };

    const handleInputChange = (e) => {
        let value = e.target.value;

        // If the input is a number and less than the minimum, reset it to the minimum
        if (e.target.type === "number" && e.target.min !== undefined && parseFloat(value) < e.target.min) {
            value = "";
        }

        setInputValue(value);
    };

    if (!props.order?.order_id) {
        return <LoadingPage props={props} />;
    }

    return (
        <Container>
            <BoxPayment>
                {props?.option?.isTitle !== true && <Title>Thanh toán tiền mặt</Title>}
                {props?.option?.isInfo !== true && (
                    <OrderInfo
                        data={{
                            order_id: query.get("order_id"),
                            total_amount: props.order?.amount,
                        }}
                        style={{
                            color: "#3E941F",
                        }}
                    />
                )}
            </BoxPayment>
            <form id="payCash" style={{ width: "100%", display: "flex", justifyContent: "center" }} onSubmit={onSubmit}>
                <Input
                    type="number"
                    required
                    placeholder="Nhập số tiền"
                    min={0}
                    value={inputValue}
                    onChange={handleInputChange}
                />
                <ButtonSubmit type="submit" style={{ height: "100%" }}>
                    Thanh toán
                </ButtonSubmit>
            </form>
        </Container>
    );
};
export default PaymentCheckoutCash;
