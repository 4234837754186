import axiosClient from './axiosClient';

/**
 * * Common
 */
const ROUTE = '/v1';

const MODEL_CHECKOUT = process.env.REACT_APP_ROUTE_CHECKOUT;
const MODEL = process.env.REACT_APP_ROUTE_SIGNATURE;

const GET_APP_ID_MERCHANT = `${ROUTE}/${MODEL_CHECKOUT}/merchant_payment_methods`;
const GET_PAYMENT_METHOD = `${ROUTE}/${MODEL_CHECKOUT}/payment_methods`;

const CREATE_SIGNATURE = `${ROUTE}/${MODEL}/sacombank`;
const CREATE_URL_PAYMENT = `${ROUTE}/${MODEL}/sacombank/order`;

const GET_ORDER_TRANSACTION = `${ROUTE}/${MODEL_CHECKOUT}/order`;

const merchantService = {
  getAppIDSignature: (queryParams) => {
    return axiosClient.get(GET_APP_ID_MERCHANT, {
      params: queryParams,
    });
  },

  getPaymentMethod: (queryParams) => {
    return axiosClient.get(GET_PAYMENT_METHOD, {
      params: queryParams,
    });
  },

  createSignature: (params) => {
    return axiosClient.post(CREATE_SIGNATURE, params);
  },

  getOrderIDTransaction: ({ _id }) => {
    return axiosClient.get(`${GET_ORDER_TRANSACTION}/${_id}`);
  },

  createURLPayment: (params) => {
    return axiosClient.post(CREATE_URL_PAYMENT, params);
  },
};

export default merchantService;
